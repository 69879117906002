<script>
  import {guiText} from "../../tstore/guiText";
  import {myAuthObject} from "../../tstore/myauth";
  import { onMount, onDestroy } from "svelte";   
  import {calendarEventPost} from "../../api/CalendarEventPost";     
  import Pikaday from "pikaday";

  export let color = "light";
  export let my_lang = 'en';
  export let toggleModalWindow;
  export let header_text;
  export let momentum_id;
  export let momentum_title;
  export let momentum_description;
  export let momentums;

  // capitalize
  let rawButtonBase = "prosper_text_a background-transparent px-4 ";
  rawButtonBase += "hover:text-emerald-600 outline-none focus:outline-none ";
  rawButtonBase += "mr-1 mb-1 mt-1 ease-linear transition-all duration-150 "; 

  let buttonBase = rawButtonBase + " py-4 text-xs";
  let defHeaderText = rawButtonBase + " font-bold py-4 text-bold bold text-left";
  let checklist_content_theme_a = rawButtonBase + " py-0 text-xs font-bold text-left";
  let checklist_content_theme_b = rawButtonBase + " py-0 text-xs";

  let my_ac_action = 'momentum';
  let now_date_pre = new Date();
  let now_date = new Date(now_date_pre.getFullYear(), now_date_pre.getMonth(), now_date_pre.getDate(), 0, 0, 0);  
  let picker_start;
  let picker_end; 

  const filterData = [ 
    { "value": "female", "text": "female",  "question": "female" },
    { "value": "male", "text": "male",  "question": "male" },
    { "value": "single", "text": "single",  "question": "single" },
    { "value": "single parent", "text": "single parent",  "question": "single parent" },
    { "value": "has family", "text": "has family",  "question": "has family" },
    { "value": "under 30 years", "text": "under 30 years",  "question": "under 30 years" },
    { "value": "over 40 years", "text": "over 40 years",  "question": "over 40 years" }  
  ];

  let defTxtCol = 'bg-red-800 text-white';
  if (color === 'light'){ defTxtCol= 'bg-white'; }
  const flex_starter = "relative content-top items-top justify-top w-full heightdefs ";
  const flex_ender = "mr-auto text-center break-words shadow-lg rounded " + defTxtCol;
  let defClassItem = flex_starter + "lg:w-12/12 mb-2 " + flex_ender;

  let defined_event = {
    l2themeValue : momentum_id,
    calendar_title : momentum_title,
    calendar_description : momentum_description,
    picker_start : '',
    picker_start2 : '',
    picker_end : '',
    picker_end2 : '',
    language : my_lang,
    surveyId : $myAuthObject.surveyId,
    start_email : '', 
		end_email : '',
    filter : '',
    event_type : my_ac_action,
    my_ac_action_header : $guiText.general.checkup[my_lang],
    prosper_checkheader_text : $guiText.general.checklist[my_lang],
    prosper_checkheader_momentum : $guiText.general.momentum_survey[my_lang],
    prosper_checkheader_filter : $guiText.general.respondents_selection[my_lang],
    prosper_checkheader_title : $guiText.general.title[my_lang],
    prosper_checkheader_description : $guiText.general.description[my_lang],
    prosper_checkheader_start_date : $guiText.general.invitation_date[my_lang],
    prosper_checkheader_end_date : $guiText.general.reminder_date[my_lang],
    prosper_checkheader_start_email : $guiText.general.inform_email_start[my_lang],
    prosper_checkheader_end_email : $guiText.general.inform_email_end[my_lang]
  };

  onMount(async () => { 
    // init Datums-library
    const element_start = document.querySelector('#pikaday_start');    
    picker_start = new Pikaday({ field: element_start, format: 'DD.MM.YYYY' });    

    const element_end = document.querySelector('#pikaday_end');
    picker_end = new Pikaday({ field: element_end,  format: 'DD.MM.YYYY' });
  });

  onDestroy(async () => {
    picker_start.destroy();
    picker_end.destroy();
  });  

  const getActiveMomentumSurvey = () => {       
    defined_event.calendar_description = '';
    defined_event.calendar_title = '';
    for ( let citm in momentums ) {
      if ( momentums[citm].value === defined_event.l2themeValue ) {
        defined_event.calendar_title = momentums[citm].text;
        defined_event.calendar_description = momentums[citm].description;
        break;
      }
    }
  }

  const prosperMomentumChanged = () => { 
    getActiveMomentumSurvey();
    checkEventDefinitionState(); 
  }
  
  const prosperFilterChanged = () => { checkEventDefinitionState(); }
  
  const prosperPikadayStartChanged = () => { 
    // 27.03.2024
    defined_event.picker_start = picker_start.toString(); 
    // 2024-03-16T23:59
    defined_event.picker_start2 = picker_start.toString('YYYY-MM-DD') + "T00:00";
    checkEventDefinitionState();
  }

  const prosperPikadayEndChanged = () => { 
    defined_event.picker_end = picker_end.toString(); 
    defined_event.picker_end2 = picker_end.toString('YYYY-MM-DD') + "T23:59";
    checkEventDefinitionState();    
  }

  const checkEventDefinitionLogic = () => {
    /** 
     * defined_event.l2themeValue ist hier ein INTEGER !!!
     * my_ac_action === 'momentum' -> l2themeValue ist ein INT
     * my_ac_action !== 'momentum' -> l2themeValue ist ein STRING
     * ...
     * 
     * event-start darf nicht in vergangenheit liegen 
     * event-start muss vor event-end liegen ! 
     */

    let pcb_state = false;

    // 27.03.2024
    // defined_event.picker_start = picker_start.toString(); 

    if (  my_ac_action === 'momentum' &&
          ! ( defined_event.l2themeValue in [ '', 0, '0' ] ) && 
          defined_event.filter.length > 3 &&
          defined_event.calendar_title.length > 3 &&
          defined_event.calendar_description.length > 3 &&
          defined_event.picker_start.length === 10 &&
          defined_event.picker_end.length === 10      
    ) { 
      let dstart = new Date( `${defined_event.picker_start.slice(6)}.${defined_event.picker_start.slice(3,5)}.${defined_event.picker_start.slice(0,2)}`);
      let dend = new Date( `${defined_event.picker_end.slice(6)}.${defined_event.picker_end.slice(3,5)}.${defined_event.picker_end.slice(0,2)}`);      
      if ( dstart >= now_date && dend >= dstart ) { pcb_state = true; }
    }
    return pcb_state;
  }

  const checkEventDefinitionState = () => {

    let pcb_color = "CFCECE";
    let pcb_state = false;
    let pcd_csb = document.getElementById('prosperCalendarSendButton');

    if ( checkEventDefinitionLogic() === true ) {
      pcb_state = true;
      pcb_color = "A6CD8A";
    }

    pcd_csb.setAttribute('style', `align-items: flex-end; width: 80%; color: #ffffff; background-color: #${pcb_color};`);  

    return pcb_state;
  }

  const checkButtonClick = () => { if ( checkEventDefinitionLogic() === true ) { apiRunner(); } }

  const apiRunner = async () => {
    let rawresp = await calendarEventPost({'authO': $myAuthObject, 'guiTxt': $guiText }, defined_event);
    let resp = await rawresp.data;    

    let pcd_csb = document.getElementById('prosperCalendarSendButton');
    pcd_csb.setAttribute('style', `align-items: flex-end; width: 80%; color: #ffffff; background-color: #CFCECE;`);

    let modal_close_button = document.getElementById('headerCloseButton');  

    let note_text = $guiText.general.error_sending_data[my_lang];
    let note_style = "242, 0, 0";    
    let pcd_note = document.getElementById('main_popup_note');

    defined_event.l2themeValue = '';
    defined_event.filter = ''; 
    defined_event.calendar_title = '';
    defined_event.calendar_description = ''; 
    defined_event.picker_start = ''; 
    defined_event.picker_start2 = ''; 
    defined_event.picker_end = ''; 
    defined_event.picker_end2 = ''; 
    defined_event.start_email = ''; 
    defined_event.end_email = '';   

    if ( resp !== false && modal_close_button !== false ) { 
        pcd_note = document.getElementById('main_popup_note');
        note_text = $guiText.general.data_was_sent_successfully[my_lang];
        note_style = "242, 172, 52";
    };

    if ( modal_close_button !== false ) {
      pcd_note.innerHTML = `
            <div class="text-blueGray-900 text-center font-bold text-sm mb-2 px-2"
              style="background-color: rgb(${note_style})"
            > ${note_text} </div> `;    
    };

    await setTimeout(toggleModalWindow, 4000);

    return true;    
  }

</script>

<style>

  .checklist_content {
    display: grid;
    grid-template-columns: 95%;
    grid-template-rows: auto;    
  }

  .checklist_input {
    width: 100%; 
    padding: 1px 1px; 
    margin: 1px 0; 
    box-sizing: border-box;
    text-transform: capitalize;
  }  

  #main_popup {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto auto;
    position: relative; 
    z-index: 50; 
  }

  #main_popup_header { grid-column: 1; grid-row: 1;}
  #main_popup_body { 
    width: 412px; 
    height: 680px;
    grid-column: 1; 
    grid-row: 2;}
  #main_popup_note { 
    grid-column: 1; 
    grid-row: 3;
    background-color: #ffffff; 
  }
  #main_popup_legend { grid-column: 1; grid-row: 4;}


</style>

<div id="prosper_overlay"></div>     

<div class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">  
  <div class="overflow-x-hidden overflow-y-auto fixed z-50" >
    <div class="border-0 rounded-lg shadow-lg w-full h-full bg-white" >                    
      <div class="mb-0 border-0">

        <div id="main_popup">

          <div id="main_popup_header" class="flex">
            <div class="lg:w-10/12 w-full prosper_text_l2_strong_weak block font-semibold text-center 
                      {color === 'light' ? 'text-blueGray-700' : 'text-white'}">
              {header_text}
            </div>
            
            <div>
              <button 
                class="lg:w-2/12 bg-red-400 text-white float-right active:bg-red-500 font-bold uppercase 
                        text-xs px-4 py-2 rounded shadow " 
                type="button"          
                id="headerCloseButton"         
                on:click={toggleModalWindow} > x </button>
            </div>

          </div>

          <div id="main_popup_body"> 

              <div class="heightdefs">
                <div class="{defClassItem}">
                  <div class="checklist_content">           

                    <div class="{defHeaderText}" style="font-size: 100%;">   
                      {$guiText.general.checklist[my_lang]}
                    </div>        

                    <div class="checklist_content_a {checklist_content_theme_a}">
                      <label for="filter"> {defined_event.prosper_checkheader_filter} <span style="color:red; fontSize:12px;">*</span></label>
                    </div>
                    <div class="checklist_content_a {checklist_content_theme_b}">
                      <select 
                        name="prosper_filter" 
                        id="prosper_filter" 
                        class="checklist_input {buttonBase}" 
                        bind:value={defined_event.filter}
                        on:change={prosperFilterChanged}              
                      >
                        <option value="0"> <i class="fa-solid fa-caret-down"></i>  </option>
                        {#each filterData as citm}
                          <option 
                            value={citm.value} 
                            class="checklist_content_a {checklist_content_theme_b}"
                            style="text-transform: capitalize;"
                          >
                            {citm.text}    
                          </option>
                        {/each}
                      </select>
                    </div>

                    <div class="checklist_content_a {checklist_content_theme_a}">
                      <label for="filter"> {defined_event.prosper_checkheader_momentum} <span style="color:red; fontSize:12px;">*</span></label>
                    </div>
                    <div class="checklist_content_a {checklist_content_theme_b}">
                      <select 
                        name="prosper_momentum" 
                        id="prosper_momentum" 
                        class="checklist_input {buttonBase}" 
                        bind:value={defined_event.l2themeValue}
                        on:change={prosperMomentumChanged}                                      
                      >
                        <option value="0"> <i class="fa-solid fa-caret-down"></i>  </option>
                        {#each momentums as citm}
                          <option 
                            value={citm.value} 
                            class="checklist_content_a {checklist_content_theme_b}"
                            style="text-transform: capitalize;"
                          >
                            {citm.text}
                          </option>
                        {/each}
                      </select>
                    </div>                    

                    <div class="checklist_content_a {checklist_content_theme_a}"> 
                      {defined_event.prosper_checkheader_description} <span style="color:red; fontSize:12px;">*</span>
                    </div>          
                    <div class="checklist_content_a {checklist_content_theme_b}"> 
                      <textarea 
                        type="text" 
                        rows=4
                        id="prosper_calendar_description" 
                        name="prosper_calendar_description"                            
                        on:change={checkEventDefinitionState}
                        bind:value={defined_event.calendar_description}                            
                        class="checklist_input  {checklist_content_theme_b}"
                      />
                      <!-- /textarea -->  
                    </div>                    

                    <div class="checklist_content_a {checklist_content_theme_a}"> 
                      {defined_event.prosper_checkheader_start_date} <span style="color:red; fontSize:12px;">*</span>
                    </div>          
                    <div class="checklist_content_a {checklist_content_theme_b}"> 
                      <input 
                        class="checklist_input {buttonBase}" 
                        type="text" 
                        autocomplete="off" 
                        id="pikaday_start" 
                        on:change={prosperPikadayStartChanged} 
                        bind:value={defined_event.picker_start}
                        >
                    </div>

                    <div class="checklist_content_a {checklist_content_theme_a}">             
                      {defined_event.prosper_checkheader_end_date} <span style="color:red; fontSize:12px;">*</span> 
                    </div>          
                    <div class="checklist_content_a {checklist_content_theme_b}"> 
                      <input 
                        class="checklist_input {buttonBase}" 
                        type="text" 
                        autocomplete="off" 
                        id="pikaday_end" 
                        on:change={prosperPikadayEndChanged}
                        bind:value={defined_event.picker_end}
                        > 
                    </div>

                  </div>

                  <button          
                    class=" font-bold uppercase text-xs rounded shadow hover:shadow-md outline-none focus:outline-none 
                          ease-linear transition-all duration-150 relative px-4 py-1 mr-1 mt-4 ml-4"
                    type="button"
                    style="align-items: flex-end; width: 80%; color: #ffffff; background-color: #CFCECE;"
                    id="prosperCalendarSendButton"
                    on:click={checkButtonClick} 
                  > 
                    {$guiText.general.send[my_lang]}
                  </button>

                </div>
              </div>
          </div>

          <div id="main_popup_note"> </div>          
          <div id="main_popup_legend"> </div>

        </div>

      </div>
    </div>
  </div>
</div>