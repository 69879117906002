import { writable} from "svelte/store";

let cx = [ 
    { 'pos' : 1, 
      'lbl': 'General fit', 
      'lnk' : 'momentum/1784217/FcG8ZJ', 
      'exp' : "Perception of the supplier's overall performance" },
    { 'pos' : 2, 
      'lbl': 'Presales & Sales',  
      'lnk' : 'momentum/1784230/lvHAGg', 
      'exp' : 'Including marketing, (pre)sales, offering, and negotiations' },
    { 'pos' : 3, 
      'lbl': 'Network Implementation',  
      'lnk' : 'momentum/1784369/gIl7n7', 
      'exp' : 'Incorporating project management, site works, installation, and commissioning' },
    { 'pos' : 4, 
      'lbl': 'HW Delivery and Logistics', 
      'lnk' : 'momentum/1784387/3cwCXU', 
      'exp' : 'Including hardware delivery content and process' },
    { 'pos' : 5, 
      'lbl': 'Systems Integration', 
      'lnk' : 'momentum/1784394/34S2Wo', 
      'exp' : 'Incorporating multivendor integration and testing services' },
    { 'pos' : 6, 
      'lbl': 'Managed Services', 
      'lnk' : 'momentum/1784401/9ylV7T', 
      'exp' : 'Involving network and service operations, and XaaS Services' },
    { 'pos' : 7, 
      'lbl': 'Network Planning, Design & Optimization', 
      'lnk' : 'momentum/1784418/IXFp9M', 
      'exp' : "Services to configure and enhance the network according to the customer's needs" },
    { 'pos' : 8, 
      'lbl': 'Support and Maintenance', 
      'lnk' : 'momentum/1784418/IXFp9M', 
      'exp' : 'Field & remote support, SW and HW support, SW upgrades, predictive services' },
    { 'pos' : 9, 
      'lbl': 'Training services', 
      'lnk' : 'momentum/1784429/2PJKyr', 
      'exp' : 'Involving onsite and online training, learning apps' }, 
    { 'pos' : 10, 
      'lbl': 'Business Relationship', 
      'lnk' : 'momentum/1784440/5FIHI2', 
      'exp' : 'Incorporating communication, customer orientation, competence, and empowerment' },
    { 'pos' : 11, 
      'lbl': 'Technology and Solutions', 
      'lnk' : 'momentum/1784443/27f6ph', 
      'exp' : 'Regarding product strength, time to market, roadmaps, and cost' },
    { 'pos' : 12, 
      'lbl': 'Complete survey', 
      'lnk' : 'survey/1784099/vhy1Qm', 
      'exp' : 'Including all phases of the customer-supplier interaction' }
  ];

  let myDefault = { 'cx' : cx }

export const momentumCxData = writable(myDefault)