<script>
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";  
  import DashboardAccount from "components/Dashboards/DashboardAccount.svelte";
  import NoRights from "components/Cards/NoRights.svelte";
  import CopyrightFooter from "components/Cards/CopyrightFooter.svelte";  

  import {guiText} from "../../tstore/guiText"; 

  // import {checkWhoAmI} from "../../lib/checkAuthObject";  
  import {myAuthObject} from "../../tstore/myauth";
  import {checkWhoAmI, checkForRoleAdmin, checkForRoleAudience} from '../../lib/checkAuthObject.js'; 
//   import { select_option } from "svelte/internal";
//   import { logInPost } from "../../api/LogInPost";

  export let my_lang;
  export let color = "light";

  if ( checkWhoAmI($myAuthObject) === false || $myAuthObject.whoami.valid === false ) {
    navigate('/auth/login', { replace: true }); 
  }

  const roleAdminStatus = checkForRoleAdmin($myAuthObject);
  const roleAudienceStatus = checkForRoleAudience($myAuthObject);

  onMount(async () => { 
    
    $myAuthObject.shop_status.status 
    if ( $myAuthObject.shop_status.hasOwnProperty('status') === false ) { 
      navigate('/auth/login', { replace: true });
    }

    if ( roleAdminStatus === false && roleAudienceStatus === false ) {
      navigate('/auth/login', { replace: true }); 
    };
  });

</script>



    {#if $myAuthObject['shop_status']['status'] === 'open' }
      {#if roleAdminStatus === true || roleAudienceStatus === true } 
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">  
          <DashboardAccount my_lang="{my_lang}"/>
        </div>
      {:else}
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">                
          <NoRights my_lang="{my_lang}" 
          my_msg_head="{$guiText.general.no_rights[my_lang]}!" 
          my_msg_body="{$guiText.general.no_rights_to_log_in[my_lang]}."
          my_msg_link="" 
          my_msg_link_txt="" />          
        </div>
      {/if}      
    {/if}

    <CopyrightFooter 
      roleAdminStatus={roleAdminStatus}
      my_lang={my_lang}
      color={color}
    />
