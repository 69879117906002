export function checkWhoAmI(autho) {

    if ( 
        autho.hasOwnProperty('whoami') === true &&
        autho.whoami.valid === true && 
        autho.whoami.hasOwnProperty('uuid') === true &&
        autho.whoami.uuid.length > 20 ) { 
            return true;
        }
    else {
        return false;
    }
};

export function checkForRoleX(autho, role) {

    const all_roles = ['ROLE_ROOT', 'ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 
        'ROLE_FINANCE', 'ROLE_MANAGER', 'ROLE_SUBSCRIBER', 'ROLE_PROJECT', 
        'ROLE_ASSIST', 'ROLE_AUDIENCE'];

    if (
        all_roles.includes(role) && 
        autho.hasOwnProperty('whoami') === true && 
        autho.whoami.hasOwnProperty('tenantRoles') === true && 
        autho.whoami.tenantRoles[0]['roles'].includes(role) === true ) {
            return true; 
        } 
    else { return false; }
};

export function checkForRoleAdmin(autho) { return checkForRoleX(autho, 'ROLE_ADMIN'); };

export function checkForRoleManager(autho) { return checkForRoleX(autho, 'ROLE_MANAGER'); };

export function checkForRoleAudience(autho) { return checkForRoleX(autho, 'ROLE_AUDIENCE'); };
