<script>
  // core components
  // import UserDropdown from "components/Dropdowns/UserDropdown.svelte";
  
  import { link } from "svelte-routing";
  import {guiText} from "../../tstore/guiText"; 
  import {myAuthObject} from "../../tstore/myauth";

  export let my_lang;

  let userLoggedIn = $guiText.general.unknown[my_lang];

  $: userLoggedIn && checkHeaderText();

  const checkHeaderText = () => {

    if ( $myAuthObject.firstname && $myAuthObject.firstname.length > 0 ) {
      if ( $myAuthObject.lastname && $myAuthObject.lastname.length > 0 ) {
        userLoggedIn = `${$myAuthObject.firstname} ${$myAuthObject.lastname}`;
      } 
      else { userLoggedIn = `${$myAuthObject.firstname}`; }
    } 
    else if ( $myAuthObject.lastname && $myAuthObject.lastname.length > 0 ) {
      userLoggedIn = `${$myAuthObject.lastname}`;
    }

  }

</script>

<!-- Navbar -->
<nav class="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-2" >

  <div class="w-full mx-auto items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4" >

    <!-- Brand -->
    <div class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-transform: capitalize" >      
    </div>

    <div class="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
      <div class="relative flex w-full flex-wrap items-stretch">

            {#if userLoggedIn === $guiText.general.unknown[my_lang] || userLoggedIn === '' }
            <div class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-transform: capitalize">            
              {userLoggedIn}
            </div>              
            {:else} 

            <div class="relative flex text-sm py-2 px-4 font-normal w-full whitespace-nowrap bg-transparent text-blueGray-700 text-transform: capitalize">
              <div>
                <a use:link href="/admin/profil" class="text-xs py-3 px-3 font-bold block text-lightBlue-600 hover text-lightBlue-400 capitalize">
                  <i class="fas fa-user mr-2 text-sm text-blueGray-400"></i> {userLoggedIn} 
                </a>
              </div>

              <div title="company">
                <a id="admin_navbar_tenant" use:link href="/admin/profil"  
                    class="text-xs py-3 px-3 font-bold block text-lightBlue-600 hover text-lightBlue-400 capitalize">
                  <i class="fas fa-industry mr-2 text-sm text-blueGray-400"></i> 
                </a>
              </div>

              <div title="project">
                <a id="admin_navbar_project" use:link href="/admin/profil"  
                    class="text-xs py-3 px-3 font-bold block text-lightBlue-600 hover text-lightBlue-400 capitalize">
                  <i class="fas fa-square-poll-horizontal mr-2 text-sm text-blueGray-400"></i>
                </a>
              </div>

            </div>              
            {/if}

      </div>
    </div>

  </div>
</nav>
<!-- End Navbar -->
