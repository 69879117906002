<script>
  import { navigate, link } from "svelte-routing";  
  import {selectTextOnFocus, blurOnEscape} from '../../lib/inputDirectives.js';    
  import {guiText} from "../../tstore/guiText"; 
  import {myAuthObject} from "../../tstore/myauth";
  import LightGreyButon from "../../components/Buttons/LightGreyButon.svelte";
  import {whoAmIGet} from "../../api/WhoAmIGet";
  import {logInPost} from "../../api/LogInPost";
  import {surveysGet} from "../../api/SurveysGet";  
  import {shopStatus} from "../../api/ShopStatus";

  export let my_lang; 
  
  const emailRegex = RegExp(
    /^[a-zA-Z0-9][a-zA-Z0-9\.!#$%&'’*+\-/=?^_`{|}~]*?[a-zA-Z0-9._\-]?@[a-zA-Z0-9][a-zA-Z0-9\._\-]*?[a-zA-Z0-9]?\.[a-zA-Z]{2,63}$/
  );

  const passwdRegex = RegExp( /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,}$/ );

  let myPassword = '';  
  let isMailOk = false;
  let inputErrorMessage = '';
  let logInFailedMessage = '';
  let surveys_by_tenant = {};

  $myAuthObject.selectedLang = my_lang;  
  $myAuthObject.loading = false;
  $myAuthObject.inputReady = false;

  $: inputErrorMessage && setInputErrorMessage();
  $: $myAuthObject.email && checkInputEmail();
  $: myPassword && checkInputPassword();
  
  const setInputErrorMessage = ( opMsg = '') => {
    inputErrorMessage = opMsg;
  }

  const checkInputEmail = () => {
    setInputErrorMessage();
    $myAuthObject.inputReady = false;
    isMailOk = true;

    if ( $myAuthObject.email.length === 0) {
      logInFailedMessage = '';
      isMailOk = false;
    }
    else if ( $myAuthObject.email.length < 7 || emailRegex.test($myAuthObject.email) === false  ) {
      setInputErrorMessage($guiText.register.general.enter_valid_email_format[my_lang]);
      logInFailedMessage = '';
      isMailOk = false;
    }
    else if ( $myAuthObject.password === true ) {
      $myAuthObject.inputReady = true;      
    }
  }

  const checkInputPassword = (opMsg='') => {
    setInputErrorMessage();
    $myAuthObject.inputReady = false;
    $myAuthObject.password = true; 

    if (opMsg.length > 1) { 
      setInputErrorMessage($guiText.general.login_failed[my_lang]);
      $myAuthObject.password = false;
      console.log($guiText.general.login_failed[my_lang]);
    }
    else if ( myPassword.length < 4 ) { 
      $myAuthObject.password = false; 
    }
    else if ( passwdRegex.test(myPassword) === false ) { 
      setInputErrorMessage($guiText.register.general.password_min_seven[my_lang]);
      $myAuthObject.password = false; 
    }
    else if ( isMailOk === true ) {
      $myAuthObject.inputReady = true;  
    }
  }

  const checkButtonClick = () => {
    if ( $myAuthObject.inputReady === true ) {
      $myAuthObject.firstname = '';
      $myAuthObject.lastname = '';
      $myAuthObject.userUuid = '';
      $myAuthObject.bearer = '';
      logInFailedMessage = '';
      apiRunner();      
    }
  }

  const whoAmIGetStatus = async () => {
    $myAuthObject.loading = true;
    let rawresp = await whoAmIGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    let resp = await rawresp.data;

    if ( rawresp.status === true && resp.uuid.length > 30 && resp.email === $myAuthObject.email && resp.email.length >= 7 ) { 
      $myAuthObject.firstname = resp.firstName;
      $myAuthObject.lastname = resp.lastName;
      $myAuthObject.email = resp.email;
      $myAuthObject.userUuid = resp.uuid;

      if ( resp.tenantRoles.length > 0 ) {
        if ( resp.tenantRoles[0]['tenantUuid'] ) {
          $myAuthObject.tenantUuid = resp.tenantRoles[0]['tenantUuid'];
        } else {
          logInFailedMessage = $guiText.general.login_failed[my_lang] + ' - problem: tenantUuid';
          console.log(logInFailedMessage);
          return false;
        }

        if ( resp.tenantRoles[0]['roles'] ) {
          $myAuthObject.tenantRole = resp.tenantRoles[0]['roles'];
        } else {
          logInFailedMessage = $guiText.general.login_failed[my_lang] + ' - problem: tenantRoles';
          console.log(logInFailedMessage);
          return false;
        }        
      }

      $myAuthObject.project = '';
      $myAuthObject.whoami = resp;
      return true;
    } 
    else {
      logInFailedMessage = $guiText.general.login_failed[my_lang];
      return false;
    }
  }

  const apiRunner = async () => {
    let rawresp = await logInPost({'authO': $myAuthObject, 'guiTxt': $guiText }, myPassword);
    let resp = await rawresp.data;

    if ( rawresp.status === true && resp.type === 'Bearer ' && resp.token.length > 30 ) {
      $myAuthObject.bearer = resp.token;
      let whoami = await whoAmIGetStatus();

      if ( whoami === true) {
        // let islandUrl = `${$myAuthObject.uri_prosper_mn}/${$myAuthObject.userUuid}/landingpage?lang=${my_lang}`;
        // window.open(islandUrl, '_blank').focus();

        let surveysRawResp = await surveysGet({'authO': $myAuthObject, 'guiTxt': $guiText });
        // surveysAry = await surveysRawResp.data; 
        surveys_by_tenant = await surveysRawResp.by_tenant;

        $myAuthObject.projectId = surveys_by_tenant[$myAuthObject.tenantUuid][0]['bot'];
        $myAuthObject.surveyId = surveys_by_tenant[$myAuthObject.tenantUuid][0]['surveyId'];

        // shop state 
        let rawShopStatus = await shopStatus({'authO': $myAuthObject, 'guiTxt': $guiText });
        let myShopStatus = await rawShopStatus.data;

        if ( myShopStatus.status === false ) {
          $myAuthObject['shop_status'] = { 'status' : 'unknown' }
        } else { $myAuthObject['shop_status'] = myShopStatus.data; }

        // TODO:
        // einige der hier stattfindenden prüfungen müssen im backend erfolgen,
        // d.h. ausgelagert werden !
        // gemeint ist das verbinden von user-data (whoami java) mit den shopdaten
        // des projektes (riskmanager.subscription)
        // hier:
        // surveysGet -> surveysRawResp -> surveys_by_tenant
        // mit
        // shopStatus -> rawShopStatus
        // rawShopStatus wird abgefragt über den tenant (projekt, bot), mit 
        // projectId = surveys_by_tenant[$myAuthObject.tenantUuid][0]['bot']
        // der tenant kann javaseitige mehrere projekte besitzen
        // der rest des systems beherscht das nicht, deswegen wird das erste 
        // projekt des tenants genommen !

        navigate('/admin/dashboard', { replace: true });  
      } 
      else { 
        logInFailedMessage = $guiText.general.login_failed[my_lang];               
        $myAuthObject.inputReady = false;
        $myAuthObject.loading = false;  }
    } 
    else {
      $myAuthObject.inputReady = false;  
      $myAuthObject.loading = false; 
      myPassword = '';
      logInFailedMessage = $guiText.general.login_failed[my_lang];
      // checkInputPassword($guiText.general.login_failed[my_lang]);
    }
  }

</script>

<div class="container mx-auto px-4 h-full">
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full lg:w-6/12 px-4">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0" >
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0"> 

          <form>
            <div class="relative w-full mb-3 py-5">
              <label
                class="block uppercase text-blueGray-900 text-xs font-bold mb-2"
                for="grid-email"
              >
                {$guiText.general.email[my_lang]}
              </label>
              <input
                id="grid-email"
                type="email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-900 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="{$guiText.general.email[my_lang]}"                
                bind:value={$myAuthObject.email}
                on:change={checkInputEmail}
                use:selectTextOnFocus 
                use:blurOnEscape                
              />
            </div>

            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-900 text-xs font-bold mb-2"
                for="grid-password"
              >
                {$guiText.general.password[my_lang]}
              </label>
              <input
                id="grid-password"
                type="password"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-900 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="{$guiText.general.password[my_lang]}"
                bind:value={myPassword}
                on:change={checkInputPassword}   
                use:selectTextOnFocus 
                use:blurOnEscape                             
              />
            </div>
          

            <div class="text-center mt-6">
              
              {#if $myAuthObject.inputReady === true}                
                {#if $myAuthObject.loading === true}    

                  <LightGreyButon bg_color = "bg-blueGray-200">
                    <div slot="button_text">... {$guiText.general.checking[my_lang]}</div>
                  </LightGreyButon>

                {:else}

                  <button
                    class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 
                        rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear 
                        transition-all duration-150"
                    type="button"
                    on:click={checkButtonClick}                       
                  > 
                    {$guiText.general.login[my_lang]}
                  </button>
                {/if}

              {:else}
                <LightGreyButon bg_color = "bg-blueGray-200">
                  <div slot="button_text">{$guiText.general.login[my_lang]}</div>
                </LightGreyButon>

              {/if}

            </div>

            {#if inputErrorMessage.length > 0 }
              <span class="block ml-2 text-xs text-red-400">
                {inputErrorMessage}
              </span>  
            {/if}

            {#if logInFailedMessage.length > 0 }
              <span class="block ml-2 text-xs text-red-400">
                {logInFailedMessage}
              </span>  
            {/if}            

          </form>

        </div>
      </div>
      <div class="flex flex-wrap mt-6 relative">
        
        <div class="w-1/2">

        </div>

        <div class="w-1/2 text-right">
          <div class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-text-xs font-bold blueGray-900 text-transform: capitalize">
            <span class="inline-block align-middle mr-8">
              <a use:link href="/auth/register" class="text-blueGray-200">
                {$guiText.register.general.registration[my_lang]}
              </a>            
              </span>
          </div>
      </div>

      </div>
    </div>
  </div>
</div>
