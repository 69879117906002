<script>
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";    
  import {guiText} from "../../tstore/guiText";
  import {myAuthObject} from "../../tstore/myauth";
  import {checkForRoleAdmin, checkForRoleAudience} from '../../lib/checkAuthObject.js'; 
  import {tenantsGet} from "../../api/TenantsGet";
  import {surveysGet} from "../../api/SurveysGet";  
  import DashHeader from "components/Dashboards/DashHeader.svelte";
  import MomentumBPButton from "components/Dashboards/MomentumBPButton.svelte";
  import MomentumExBPModal from "components/Dashboards/MomentumExBPModal.svelte";  
  import {momentumSurveyGet} from "../../api/MomentumSurveyGet";

  export let my_lang;
  export let color = "light";

  let showModal = false;
  let bLink;
  let momentum_title = '';
  let momentum_description = '';

  let defTxtCol = 'bg-red-800 text-white';
  if (color === 'light'){ defTxtCol= 'bg-white'; }

  let buttonBase = "text-emerald-500 background-transparent font-bold capitalize px-4 ";
  const flex_starter = "relative content-top items-top justify-top w-full heightdefs ";
  const flex_ender = "mr-auto text-center break-words shadow-lg rounded " + defTxtCol;
  let defClassItem = flex_starter + "lg:w-12/12 mb-2 " + flex_ender;

  buttonBase += "hover:text-emerald-600 text-xs outline-none focus:outline-none ";
  buttonBase += "mr-1 mb-1 mt-1 ease-linear transition-all duration-150 "; 

  let tenantsLabel = '';
  let surveysLabel = ''; 
  let tenantsAry = []; 
  let surveys_by_tenant = {};
  let momentums = []; 

  const roleAdminStatus = checkForRoleAdmin($myAuthObject);
  const roleAudienceStatus = checkForRoleAudience($myAuthObject);

  const toggleModalWindow = (e) => {     
    showModal = !showModal;
    bLink = e; 
    for ( let citm in momentums ) {
      if ( momentums[citm].value === e ) {
        momentum_title = momentums[citm].text;
        momentum_description = momentums[citm].description;
        break;
      }
    }
  };

  onMount(async () => { 
    if ( roleAdminStatus === false && roleAudienceStatus === false ) { navigate('/auth/login', { replace: true }); };

    let tenantsRawResp = await tenantsGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    tenantsAry = await tenantsRawResp.data; 
    let surveysRawResp = await surveysGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    surveys_by_tenant = await surveysRawResp.by_tenant;

    tenantsLabel = tenantsAry[0].label;
    surveysLabel = surveys_by_tenant[tenantsAry[0].uuid][0]['label'];    

    let eleT = document.getElementById('admin_navbar_tenant');
    eleT.innerHTML = '';
    eleT.insertAdjacentHTML('beforeend', '<i class="fas fa-industry mr-2 text-sm text-blueGray-400"></i>');
    eleT.insertAdjacentText('beforeend', tenantsLabel);
    
    let eleP = document.getElementById('admin_navbar_project');
    eleP.innerHTML = '';
    eleP.insertAdjacentHTML('beforeend', '<i class="fas fa-square-poll-horizontal mr-2 text-sm text-blueGray-400"></i>');
    eleP.insertAdjacentText('beforeend', surveysLabel);      

    // momentumSurveyGet
    // L2 Labels welche im Pulldown angezeigt werden
    let rawresp = await momentumSurveyGet( {'authO': $myAuthObject, 'guiTxt': $guiText } );
    let resp = await rawresp.data;
    
    if ( resp === false ) {
      momentums.push({
            "value": 0,
            "text": "API failed",
            "description": `${$guiText.general.error_unknown_occured[my_lang]} ${$guiText.general.contact_your_manager[my_lang]}`,
            "items": [
                {
                    "itemId": "0_0_0",
                    "label": "API failed",
                    "description": `${$guiText.general.error_unknown_occured[my_lang]} ${$guiText.general.contact_your_manager[my_lang]}`
                }]
            });
    } else {
      momentums = resp;
    }

  });

</script>

{#if showModal}  
  <MomentumExBPModal 
      color={color} 
      my_lang={my_lang}
      toggleModalWindow={toggleModalWindow}
      header_text={$guiText.general.momentum_surveys[my_lang]}
      momentum_id={bLink}
      momentum_title={momentum_title}
      momentum_description={momentum_description}
      momentums={momentums}
  />
{/if}

{#if $myAuthObject.whoami.valid === true }

<momentum_main>
  <div id="prosper_momentum_header">
    <DashHeader 
      color="ffffff" 
      background_color="CA902D" 
      headline={$guiText.general.momentum_surveys_ex[my_lang]} show_logo="show" />
  </div>

  <div id="prosper_momentum_subheader">
    <DashHeader 
      color="ffffff" 
      background_color="F2AC34" 
      headline={$guiText.general.our_workday_ecosystem_topics[my_lang]} />
  </div>

  {#each momentums as item, idx}

    <div id="prosper_momentum_txt_{idx}">
      <div class="heightdefs">
        <div class="{defClassItem}">           
          {#if roleAdminStatus === true} 

          <div id="prosper_momentum_box_main" class="heightdefs">

            <div id="prosper_momentum_box_a">
              <div class=" text-black text-sm font-bold pt-2 mt-2 pb-2 mb-2">              
                <span class="capitalize">{item.text.slice(0,1)}</span>{item.text.slice(1)} 
              </div>
            </div>

            <div id="prosper_momentum_box_b">
              <div class="align-text-black text-sm"> 
                {item.description}
              </div>
            </div>

            <div id="prosper_box_momentum_c" class="pb-2 mb-2">
              <MomentumBPButton 
                  bText={$guiText.general.go_to_the_survey[my_lang]}   
                  bind:showModal={showModal} 
                  bind:bLink={item.value} 
                  toggleModalWindow={toggleModalWindow}  
                  background_color="EDBA64"                
                  />
            </div>

          </div>

          {/if}          
        </div>
      </div>
    </div>

  {/each}

</momentum_main>

{/if}
