<script>
  import { navigate, link } from "svelte-routing";
  import { onMount } from "svelte";    
  import {guiText} from "../../tstore/guiText";
  import {myAuthObject} from "../../tstore/myauth";
  import {checkForRoleAdmin, checkForRoleAudience} from '../../lib/checkAuthObject.js'; 
  import {tenantsGet} from "../../api/TenantsGet";
  import {surveysGet} from "../../api/SurveysGet";  
  import DashPModal from "components/Dashboards/DashPModal.svelte";
  import DashPOneIsland from "components/Dashboards/DashPOneIsland.svelte";

  import DashHeader from "components/Dashboards/DashHeader.svelte";
  import ChartSpeedOMeter from "components/Cards/ChartSpeedOMeter.svelte";

  export let my_lang;
  export let color = "light";
  export let extern_popup_pie_id = "";
  
  // let defTxtCol = 'bg-red-800 text-white';
  // if (color === 'light'){ defTxtCol= 'bg-white'; }

  const flex_starter = "relative flex content-center items-center justify-center w-full heightdefs ";
  const flex_ender = "mr-auto text-center break-words shadow-lg rounded "; // + defTxtCol;
  let defClassItem = flex_starter + "lg:w-12/12 mb-2 background-transparent " + flex_ender;

  // capitalize
  let rawButtonBase = "prosper_text_a background-transparent px-4 ";
  rawButtonBase += "hover:text-emerald-600 outline-none focus:outline-none ";
  rawButtonBase += "mr-1 mb-1 mt-1 ease-linear transition-all duration-150 "; 

  // let buttonBase = rawButtonBase + " font-bold ";
  // text-xs
  let buttonDefault = rawButtonBase + " font-bold py-4 text-bold";
  let defHeaderText = rawButtonBase + " font-bold py-4 text-bold";

  // let button_popup_class = "bg-red-400 text-white float-right active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow "; 

  let popup_pie_id = '';
  let tenantsLabel = '';
  let surveysLabel = ''; 
  let tenantsAry = []; 
  let surveys_by_tenant = {};

  const textblock = [ 
    { 'id': '1', 
      'trans' : $guiText.general.state_of_the_community[my_lang], 
      'background' : 'prosper_dashb_column_a_subheader'  },
    { 'id': '2', 
      'trans' : $guiText.general.get_everyone_involved[my_lang], 
      'background' : 'prosper_dashb_column_b_subheader' },
    { 'id': '3', 
      'trans' : $guiText.general.improve_learn_and_adapt[my_lang], 
      'background' : 'prosper_dashb_column_c_subheader' }
  ]; 
  /**
       { 'id': '4', 
      'trans' : $guiText.general.our_track_record_this_quarter[my_lang], 
      'background' : 'prosper_dashb_column_d_subheader' },
   */

  let speed_o_meter = [
    { id: "prosper_sense_of_belonging",
      myClass: "prosper_dashb_column_a_body",
      classColumn: "prosper_dashb_column_a_header",
      textHeader: $guiText.general.sense_of_belonging[my_lang],
      idSpeedOMeter: "speed_o_meter_a",
      speedOMeterData: JSON.stringify([ 4, 8, 12]),
      speedOMeterNeedle: 7,
      bgColor : [ "#C94118", "#FBD879", "#809B60"],
      myLabels : ["Critical", "Warning", "Normal"]
    },
    { id: "prosper_positive_challenges",
      myClass: "prosper_dashb_column_b_body",
      classColumn: "prosper_dashb_column_b_header",
      textHeader: $guiText.general.positive_challenges[my_lang], 
      idSpeedOMeter: "speed_o_meter_b",
      speedOMeterData: JSON.stringify([ 325, 350, 1000]),
      speedOMeterNeedle: 550,
      bgColor : [ "#C94118", "#FBD879", "#809B60"],
      myLabels : ["Critical", "Warning", "Normal"]
    },
    { id: "prosper_work_life_balance",
      myClass: "prosper_dashb_column_c_body",
      classColumn: "prosper_dashb_column_c_header",
      textHeader: $guiText.general.work_life_balance[my_lang],
      idSpeedOMeter: "speed_o_meter_c",
      speedOMeterData: JSON.stringify([ 550, 600, 800]),
      speedOMeterNeedle: 318,
      bgColor : [ "#C94118", "#FBD879", "#809B60"],
      myLabels : ["Critical", "Warning", "Normal"]
    }    
]

  const roleAdminStatus = checkForRoleAdmin($myAuthObject);
  const roleAudienceStatus = checkForRoleAudience($myAuthObject);

  // für ausgewählte projekte werden die links auf DE gesezt
  // perleberg: 55109280-13fc-11ee-9534-376b5dabbac4
  let urlLang = my_lang; 
  if ( $myAuthObject.projectId === "55109280-13fc-11ee-9534-376b5dabbac4" ) {
    urlLang = "de";
  }

  const linkRoot = `${$myAuthObject.uri_prosper_mn}/${$myAuthObject.whoami.uuid}/${$myAuthObject.projectId}`;
  const linkIterim = `lang=${urlLang}&status=true&useRandomData=onlyData`;

  const linkMobile = `${$myAuthObject.uri_prosper_mn}/mobile/${$myAuthObject.whoami.uuid}/${$myAuthObject.projectId}`;
  const linkMobileCheckUp = `${linkMobile}/checkup/startpage?lang=${urlLang}`;

  const linkMobileIsland = `${linkMobile}/prosperland?status=true&useRandomData=onlyData&lang=${urlLang}`;

  // Handle Pie Chart PopUp
  let showModalChartPie = false;
  function toggleModalChartPie(e=true){ showModalChartPie = !showModalChartPie; }; 
  function toggleModalChartPieClick(id){ 
    popup_pie_id = id;
    showModalChartPie = !showModalChartPie; 
  }; 
  
  // after mount
  if ( extern_popup_pie_id === "flash_feedback" ) { toggleModalChartPieClick('flash_feedback'); }

  // const gotoProsperLandingPage = () => { window.open(`${linkRoot}/prosperland?${linkIterim}`, '_blank').focus(); };  
  // const gotoProsperPage5 = () => { window.open(`${linkRoot}/prosperland?${linkIterim}`, '_blank').focus(); };
  const gotoProsperAction = () => { window.open(`${linkRoot}/prosperland?${linkIterim}&target=action`, '_blank').focus(); };
  const gotoProsperKpi = () => { window.open(`${linkRoot}/prosperland?${linkIterim}&elementId=2_3_1`, '_blank').focus(); };

  onMount(async () => { 
    if ( roleAdminStatus === false && roleAudienceStatus === false ) { navigate('/auth/login', { replace: true }); };

    let tenantsRawResp = await tenantsGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    tenantsAry = await tenantsRawResp.data; 
    let surveysRawResp = await surveysGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    surveys_by_tenant = await surveysRawResp.by_tenant;

    tenantsLabel = tenantsAry[0].label;
    surveysLabel = surveys_by_tenant[tenantsAry[0].uuid][0]['label'];    

    let eleT = document.getElementById('admin_navbar_tenant');
    eleT.innerHTML = '';
    eleT.insertAdjacentHTML('beforeend', '<i class="fas fa-industry mr-2 text-sm text-blueGray-400"></i>');
    eleT.insertAdjacentText('beforeend', tenantsLabel);
    
    let eleP = document.getElementById('admin_navbar_project');
    eleP.innerHTML = '';
    eleP.insertAdjacentHTML('beforeend', '<i class="fas fa-square-poll-horizontal mr-2 text-sm text-blueGray-400"></i>');
    eleP.insertAdjacentText('beforeend', surveysLabel);      

  });

</script>

<style>

  main {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: 3vw 3vw 5vw 5vw 5vw 5vw 5vw 5vw;
    grid-gap: 0.5em;
  }

  .prosper_image { object-fit: fill ; height: 27vw;  }
  .prosper_image_full { object-fit: none; }  
  .prosper_image_fill { object-fit: fill; }  

  #prosper_header { grid-column: 1 / span 4; grid-row: 1; }
  #prosper_subheader { grid-column: 1 / span 4; grid-row: 2; }

  #prosper_sense_of_belonging { grid-column: 1; grid-row: 3 / span 2; }
  #prosper_text_1 {             grid-column: 1; grid-row: 5; }
  #prosper_view_details {       grid-column: 1; grid-row: 6; }  
  #table_of_results {           grid-column: 1; grid-row: 7; }
  #prosper_kpi {                grid-column: 1; grid-row: 8; }    

  #prosper_positive_challenges {  grid-column: 2; grid-row: 3 / span 2; }
  #prosper_text_2 {               grid-column: 2; grid-row: 5; }    
  #prosper_plan_feedback {        grid-column: 2; grid-row: 6; }
  #prosper_momentum {             grid-column: 2; grid-row: 7; }
  #prosper_flash_feedback {       grid-column: 2; grid-row: 8; }  

  #prosper_work_life_balance {  grid-column: 3; grid-row: 3 / span 2; }  
  #prosper_text_3 {             grid-column: 3; grid-row: 5; }    
  #prosper_plan_action {        grid-column: 3; grid-row: 6; }
  #prosper_campagne {           grid-column: 3; grid-row: 7; }
  #prosper_action {             grid-column: 3; grid-row: 8; }

  #prosper_landing_page { grid-column: 4; grid-row: 3 / span 6; }

  @media (max-width: 640px) {
    main {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto;
      grid-gap: 1em;
    }

    #prosper_header { grid-column: 1; grid-row: 1; }
    #prosper_subheader { grid-column: 1; grid-row: 2; }

    #prosper_sense_of_belonging { grid-column: 1; grid-row: 3; }
    #prosper_text_1 { grid-column: 1; grid-row: 4; }
    #prosper_view_details { grid-column: 1; grid-row: 5; }  
    #table_of_results { grid-column: 1; grid-row: 6; }
    #prosper_kpi { grid-column: 1; grid-row: 7; }

    #prosper_positive_challenges { grid-column: 1; grid-row: 8; }
    #prosper_text_2 { grid-column: 1; grid-row:9; }    
    #prosper_plan_feedback{ grid-column: 1; grid-row: 10; }
    #prosper_momentum { grid-column: 1; grid-row: 11; }
    #prosper_flash_feedback { grid-column: 1; grid-row: 12; }  

    #prosper_work_life_balance { grid-column: 1; grid-row: 13; }
    #prosper_text_3 { grid-column: 1; grid-row: 14; }    
    #prosper_plan_action { grid-column: 1; grid-row: 15; }
    #prosper_campagne { grid-column: 1; grid-row: 16; }
    #prosper_action { grid-column: 1; grid-row: 17; }

    #prosper_landing_page { grid-column: 1; grid-row: 18; }

    .prosperland_modal {
      position: absolute; 
      top: 1%; 
      left: 0%; 
      transform: translate(-0%, -0%);    
      }    
  }

  @media (min-width: 641px) {
    .prosperland_modal {  
      position: absolute; 
      top: 50%; 
      left: 50%; 
      transform: translate(-50%, -50%);    
    }
  }

</style>


{#if showModalChartPie}  
  <DashPModal  
    popup_pie_id={popup_pie_id} 
    header_text={$guiText.general.momentum_surveys[my_lang]}   
    toggleModalChartPie={toggleModalChartPie}
    linkRoot={linkRoot} 
    linkIterim={linkIterim}
    linkMobileCheckUp={linkMobileCheckUp}
  />
{/if}


{#if $myAuthObject.whoami.valid === true }

<main>
  <div id="prosper_header">
    <DashHeader 
      color="ffffff" 
      background_color="076623" 
      headline={$guiText.general.your_prosperland_at_your_fingertips[my_lang]} 
      show_logo="show" />
  </div>

  <div id="prosper_subheader">
    {#if $myAuthObject.projectId === 'aa7c4d06-98e3-11ee-a700-02420a010442'}
      <DashHeader 
        color="ffffff" 
        background_color="A6CD8A" 
        headline="{surveysLabel} - {$guiText.general.fiber_emea_bilbao['en']}" />
    {:else if tenantsLabel === surveysLabel }
      <DashHeader 
        color="ffffff" 
        background_color="A6CD8A" 
        headline={surveysLabel} />
    {:else}
      <DashHeader 
        color="ffffff" 
        background_color="A6CD8A" 
        headline={tenantsLabel} - {surveysLabel} />
    {/if}
  </div> 
  
  {#each textblock as item}
    <div id="prosper_text_{item.id}" class="{item.background}">
      <div class="heightdefs"> 
        <div class="{defClassItem}"> 
          <div class="{defHeaderText}" style="font-size: 100%;">
            {item.trans}
          </div>
        </div>
      </div>
    </div>
  {/each}

  {#each speed_o_meter as som}
    <div id="{som.id}" class="{som.myClass}">
      <div class="heightdefs">
        <div class="{defClassItem} ">        
          {#if roleAdminStatus === true || roleAudienceStatus === true }
            <div class="prosper_dashb_mini_container">

              <div>
                <canvas id="{som.idSpeedOMeter}"></canvas>
              </div>

              <div class="{som.classColumn}"  >
                {som.textHeader} 
              </div>  

            </div>
          {/if}  
        </div>
      </div>
      <ChartSpeedOMeter 
          mySpeedOMeter="{som.idSpeedOMeter}" 
          myTitle={som.textHeader} 
          somData={som.speedOMeterData}
          somBGColor={som.bgColor}
          somNeedle={som.speedOMeterNeedle}
          somLabels={som.myLabels}
          />
    </div>  
  {/each}


  <div id="prosper_view_details" class="prosper_dashb_column_a_body">
    <div class="heightdefs">
      <div class="{defClassItem}">    
        {#if roleAdminStatus === true || roleAudienceStatus === true  }       
          <!-- button class="{buttonDefault}" type="button" on:click={gotoProsperLandingPage}>
            {$guiText.general.our_state_of_mind[my_lang]}
          </button -->  
          <a
          use:link
          href="/admin/pieboard"
          class="{buttonDefault}">
          {$guiText.general.our_state_of_mind[my_lang]}
        </a>
        {/if}
      </div>
    </div>
  </div>

  
  <div id="prosper_plan_action" class="prosper_dashb_column_c_body"> 
    <div class="heightdefs">
      <div class="{defClassItem}">    
        {#if roleAdminStatus === true} 
          <a
            use:link
            href="/admin/actioncommunicationboard_a"
            class="{buttonDefault}">
            {$guiText.general.plan_action[my_lang]}
          </a>
        {/if}
      </div>
    </div> 
  </div>
  

  <div id="prosper_plan_feedback" class="prosper_dashb_column_b_body"> 
    <div class="heightdefs">
      <div class="{defClassItem}">    
        {#if roleAdminStatus === true} 
          <a
            use:link
            href="/admin/actioncommunicationboard_f"
            class="{buttonDefault}">
            {$guiText.general.plan_feedback[my_lang]}
          </a>
        {/if}
      </div>
    </div> 
  </div>

  <div id="prosper_flash_feedback" class="prosper_dashb_column_b_body"> 
    <div class="heightdefs">
      <div class="{defClassItem}">    
        {#if roleAdminStatus === true} 
          <button class="{buttonDefault}" type="button" on:click={() => toggleModalChartPieClick('flash_feedback')}>
            {$guiText.general.flash_feedback[my_lang]}
          </button>  
        {/if}
      </div>
    </div> 
  </div>

  <div id="prosper_momentum" class="prosper_dashb_column_b_body">
    <div class="heightdefs">
      <div class="{defClassItem}"> 
        {#if roleAdminStatus === true}           
          <a
          use:link
          href="/admin/momentumboard"
          class="{buttonDefault}">
          {$guiText.general.momentum_surveys[my_lang]}
        </a>
        {/if}
      </div>
    </div>
  </div>

  <div id="prosper_landing_page"> 
    <div class="heightdefs">
      <div class="{defClassItem} prosper_dashb_column_d_body_op1">
        {#if roleAdminStatus === true || roleAudienceStatus === true }    
          <!-- a href="#pablo" on:click={gotoProsperPage5} target="_blank">          
            <img class="prosper_image" alt="island" src="../assets/img/fke_insel.png" /> 
          </a -->
          <DashPOneIsland myTUrl={`${linkMobileIsland}`}/>
        {/if}
      </div>
    </div>      
  </div>

  <div id="prosper_campagne" class="prosper_dashb_column_c_body"> 
    <div class="heightdefs">
      <div class="{defClassItem}"> 
        {#if roleAdminStatus === true} 
          <a
            use:link
            href="/admin/actioncommunicationboard_p"
            class="{buttonDefault}">
            {$guiText.general.plan_publication[my_lang]}
          </a>
        {/if}
      </div>
    </div>
  </div>

  <div id="prosper_action" class="prosper_dashb_column_c_body"> 
    <div class="heightdefs">
      <div class="{defClassItem}"> 
        {#if roleAdminStatus === true} 
          <a
            use:link
            href="/admin/actioncommunicationboard_n"
            class="{buttonDefault}">
            {$guiText.general.do_something_now[my_lang]}
          </a>
        {/if}
      </div>
    </div>
  </div>

  <div id="prosper_kpi" class="prosper_dashb_column_a_body"> 
    <div class="heightdefs">
      <div class="{defClassItem}"> 
        {#if roleAdminStatus === true} 
          <button class="{buttonDefault}" type="button" on:click={gotoProsperKpi}>
            {$guiText.general.export_results[my_lang]}
          </button>  
        {/if}
      </div>
    </div>
  </div>

  <div id="table_of_results" class="prosper_dashb_column_a_body">   
    <div class="heightdefs">
      <div class="{defClassItem}">    
        {#if roleAdminStatus === true} 
          <button class="{buttonDefault}" type="button" on:click={gotoProsperAction}>
            {$guiText.general.table_of_results[my_lang]}
          </button>  
        {/if}
      </div>
    </div>
  </div>

</main>
 
{/if}




