<script>
  import { navigate, link } from "svelte-routing";
  import { onMount, onDestroy } from "svelte";    
  import {guiText} from "../../tstore/guiText";
  import {myAuthObject} from "../../tstore/myauth";
  import {checkForRoleAdmin, checkForRoleAudience} from '../../lib/checkAuthObject.js'; 
  import {tenantsGet} from "../../api/TenantsGet";
  import {surveysGet} from "../../api/SurveysGet";  
  import { Pulse } from 'svelte-loading-spinners';    


  // import AuthNavbar from "components/Navbars/AuthNavbar.svelte";

  export let my_lang;
  export let frame_url;

  let app_div_nav = document.querySelector('#app > div > nav');
  let app_div_div = document.querySelector('#app > div > div');
  let app_div_div_div2 = document.querySelector('#app > div > div > div:nth-child(3)');
  let app_div_nav_div_button = document.querySelector('#app > div > nav > div > button');
  // let app_div_nav_div_div = document.querySelector('#app > div > nav > div > div');
  // let app_div_nav_div_button_etc = document.querySelector('#app > div > div > nav > div > div');
  let app_dic2_nav_div2 = document.querySelector("#app > div > div > nav > div > div:nth-child(1)");

  let myWidth = "100%"; // "960px"; 
  let myHeight = "860px"; // "680px";

  let urlLang = my_lang; 
  if ( $myAuthObject.projectId === "55109280-13fc-11ee-9534-376b5dabbac4" ) { 
    urlLang = "de"; 
  }

  let buttonBase = "text-emerald-500 background-transparent font-bold capitalize px-4 ";
  buttonBase += "hover:text-emerald-600 text-xs outline-none focus:outline-none ";
  buttonBase += "mr-1 mb-1 mt-1 ease-linear transition-all duration-150 "; 

  let tenantsLabel = '';
  let surveysLabel = ''; 
  let tenantsAry = []; 
  let surveys_by_tenant = {};

  const roleAdminStatus = checkForRoleAdmin($myAuthObject);
  const roleAudienceStatus = checkForRoleAudience($myAuthObject);

  // let collapseShow = "hidden";

  let frameUrl = `${frame_url}&lang=${urlLang}`;
  

  // function toggleCollapseShow(classes) { collapseShow = classes; }

  onMount(async () => { 
    if ( roleAdminStatus === false && roleAudienceStatus === false ) { navigate('/auth/login', { replace: true }); };

    let tenantsRawResp = await tenantsGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    tenantsAry = await tenantsRawResp.data; 
    let surveysRawResp = await surveysGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    surveys_by_tenant = await surveysRawResp.by_tenant;

    tenantsLabel = tenantsAry[0].label;
    surveysLabel = surveys_by_tenant[tenantsAry[0].uuid][0]['label'];    

    let eleT = document.getElementById('admin_navbar_tenant');
    eleT.innerHTML = '';
    eleT.insertAdjacentHTML('beforeend', '<i class="fas fa-industry mr-2 text-sm text-blueGray-400"></i>');
    eleT.insertAdjacentText('beforeend', tenantsLabel);
    
    let eleP = document.getElementById('admin_navbar_project');
    eleP.innerHTML = '';
    eleP.insertAdjacentHTML('beforeend', '<i class="fas fa-square-poll-horizontal mr-2 text-sm text-blueGray-400"></i>');
    eleP.insertAdjacentText('beforeend', surveysLabel);      


    //----------------------------------------------

    // Sidebar auf Smartphone-State setzen

    // aktuelle sidebar unsichtbar setzen
    app_div_nav.style.display = 'none';

    // fläche für landingpage maximal vergrösseren
    app_div_div.style.marginLeft = '0px';

    app_div_div_div2.style.paddingLeft = '0px';
    app_div_div_div2.style.paddingRight = '0px';

    app_div_nav_div_button.style.display = "flex";
    app_div_nav_div_button.style.flexDirection = "column";
    app_div_nav_div_button.style.flexWrap = "nowrap";   

    // copyNav Bars -> click() -> toggleCollapseShow() fehlt noch 
    
    // let dashboardHeaderLink = `
    //   <a id="dashboardHeaderLink" use:link href="/admin/dashboard" class=${defaultButtonSB} >
    //     <i class="fas fa-tv mr-2 text-sm text-blueGray-300"></i>
    //     ${$guiText.general.dashboard[my_lang]} 
    //   </a>`;

    
    let eleProsperDashboardHeaderLink = document.querySelector("#prosperDashboardHeaderLink");
    app_dic2_nav_div2.appendChild(eleProsperDashboardHeaderLink);

    // document.querySelector("#app > div > div > nav > div > div");

    // hier muss mit move elements gearbeitet werden:
    // https://gomakethings.com/how-to-move-elements-around-in-the-dom-with-vanilla-javascript/

    // die Bars der Navbar erscheinen
    // app_div_nav_div_button_etc.appendChild(app_div_nav_div_div);
    // app_div_nav_div_button_etc.appendChild(app_div_nav_div_button);

    //--------------------------------------------


    const stringOnLoad = `
      const oneIslandBody = document.getElementById(\"pcampagne\");
      oneIslandBody.style.zIndex = 0;
      oneIslandBody.querySelectorAll(\"#loadingIndicator\").forEach(el => el.remove());
      `;

    const stringOnError = `
      document.getElementById(\"errorIndicator\").innerHTML = \"<i class="fa fa-bomb fa-5x text-red-600"></i>\"
      `;

    function onLoad(){
      let ifhb = document.querySelector(" #pcampagne_iframe > #document > html > body");
      console.log( ifhb );
      ifhb.style.opacity = 0;
    };

    function run_iframe(){
      document.getElementById("pcampagne").innerHTML = `<iframe 
        src='${frameUrl}' 
        onload='${stringOnLoad}' 
        onerror='${stringOnError}'
        title='Island'
        width='${myWidth}' 
        height='${myHeight}'
        id='pcampagne_iframe'
        ></iframe>`; 
    };

    setTimeout(run_iframe, 2000);


  });


  onDestroy(() => {
      app_div_nav.style = "";
      app_div_div.style = ""; 
      app_div_div_div2.style = "";
      app_div_nav_div_button.style = "";
      // app_dic2_nav_div2 = "";

      document.querySelector("#prosperDashboardHeaderLink").remove();

      // console.log( 'app_div_nav_div_button_etc');      
      // console.log( app_div_nav_div_button_etc );
	  });

</script>


<style>


.pcampagne_overlay {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;  
  z-index: 45; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  align-items: center;
  justify-items: center;
}

#loadingIndicator {
  z-index: 46; 
  place-items: center;
  align-items: center ;
  justify-content: center;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 15vw 2vw;    
  grid-gap: 0.5em; 
}

.loadingIndicatorVisibleGridA { grid-column: 1; grid-row: 1;}
.loadingIndicatorVisibleGridB { grid-column: 1; grid-row: 2; }

</style>


{#if $myAuthObject.whoami.valid === true }

<div id="pcampagne" class="pcampagne_overlay">

  <a 
    use:link 
    href="/admin/dashboard"
    id="prosperDashboardHeaderLink"  
    class="text-xs py-3 px-3 font-bold block text-lightBlue-600 hover text-lightBlue-400 capitalize" 
    >
    <i class="fas fa-tv mr-2 text-sm text-blueGray-400"></i>
      {$guiText.general.dashboard[my_lang]} 
  </a>

  <div id="loadingIndicator" class="big_iframe">

    <div id="errorIndicator" class="loadingIndicatorVisibleGridA"></div>

    <div class="loadingIndicatorVisibleGridB">
      <Pulse size="60" color="#FF3E00" unit="px" duration="1s"/>
    </div>

  </div>
</div>

{/if}
