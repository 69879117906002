<script>
  import { Router, Route, navigate } from "svelte-routing";

  // components for this layout
  import AdminNavbar from "components/Navbars/AdminNavbar.svelte";
  import Sidebar from "components/Sidebar/Sidebar.svelte";

  import HeaderStats from "components/Headers/HeaderStats.svelte";
  import FooterAdmin from "components/Footers/FooterAdmin.svelte";

  // pages for this layout
  import Dashboard from "views/admin/Dashboard.svelte";
  import Administration from "views/admin/Administration.svelte";
  import Pieboard from "views/admin/Pieboard.svelte";
  import Momentumboard from "views/admin/Momentumboard.svelte";
  import Momentumexboard from "views/admin/Momentumexboard.svelte";
  import ActionCommunicationBoard from "views/admin/ActionCommunicationBoard.svelte";

  import LandingpageFrame from "views/admin/LandingpageFrame.svelte";
  import ConfigurepageFrame from "views/admin/ConfigurepageFrame.svelte";  

  import Profil from "views/admin/Profil.svelte";  
  import Recipients from "views/admin/Recipients.svelte";

  import {myAuthObject} from "../tstore/myauth";
  import {checkWhoAmI} from "../lib/checkAuthObject";

  // export let location;
  export let my_lang;

  if ( checkWhoAmI($myAuthObject) === false ) {
    navigate('/auth/login', { replace: true }); 
  }  

</script>

<style>
.element {
  position: relative;
}

.element::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../assets/img/bg_a.png");  
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.25;
}

</style>

<div>
  <Sidebar my_lang={my_lang}/>
  <div class="relative md:ml-64 bg-blueGray-100 element">
    <AdminNavbar my_lang={my_lang}/>
    <HeaderStats />
    <div id="prosper_admin" class="px-4 md:px-10 mx-auto w-full -m-24">
      <Router url="admin">
        <Route path="dashboard"><Dashboard my_lang={my_lang}/> </Route>
        <Route path="administration"><Administration my_lang={my_lang}/> </Route>
        <Route path="pieboard"><Pieboard my_lang={my_lang}/> </Route>     
        <Route path="momentumboard"><Momentumboard  my_lang={my_lang}/></Route>   
        <Route path="momentumexboard"><Momentumexboard  my_lang={my_lang}/></Route>   
        
        <Route path="actioncommunicationboard"><ActionCommunicationBoard my_lang={my_lang} my_ac_action="checkup"/></Route> 
        <Route path="actioncommunicationboard_a"><ActionCommunicationBoard my_lang={my_lang} my_ac_action="action"/></Route>
        <Route path="actioncommunicationboard_f"><ActionCommunicationBoard my_lang={my_lang} my_ac_action="feedback"/></Route>
        <Route path="actioncommunicationboard_p"><ActionCommunicationBoard my_lang={my_lang} my_ac_action="publication"/></Route>
        <Route path="actioncommunicationboard_n"><ActionCommunicationBoard my_lang={my_lang} my_ac_action="now"/></Route>          

        <Route path="landingpageframe"><LandingpageFrame my_lang={my_lang}/></Route>
        <Route path="configurepageframe"><ConfigurepageFrame my_lang={my_lang}/></Route>

        <Route path="recipients"><Recipients my_lang={my_lang} /></Route>
        <Route path="profil"><Profil my_lang={my_lang} /></Route>        
      </Router>
      <FooterAdmin />
    </div>
  </div>
</div>
