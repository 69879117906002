<script>
    import Select from 'svelte-select';
    import { onMount, createEventDispatcher } from "svelte";
    import { navigate } from "svelte-routing";
    import {myAuthObject} from "../../tstore/myauth";  
    import {guiText} from "../../tstore/guiText";
    import {selectTextOnFocus, blurOnEscape} from '../../lib/inputDirectives.js';    
    // import {checkForRoleAdmin} from '../../lib/checkAuthObject.js';
    import LightGreyButon from "../../components/Buttons/LightGreyButon.svelte";      
    import {userPut} from "../../api/UserPut";  

    export let my_lang;
    // export let color = "light";

    let registerBg2 = "/assets/img/landingpage_bg_en.jpg";
    if (my_lang == "de") {
      registerBg2 = "/assets/img/landingpage_bg_de.jpg";          
      // registerBg2 = "/assets/img/register_bg_2.jpg"; 
    }

    let isFirstnameOk = false;
    let isLastnameOk = false;
    let isRolesOk = false;

    let inputErrorMessage = ''; 
    let edit_recipient_data = {};
    let roleItems = [ 
      {value: 'ROLE_ADMIN', label: 'Admin', group: 'roles'},
      {value: 'ROLE_FINANCE', label: 'Finance', group: 'roles',},
      {value: 'ROLE_MANAGER', label: 'Manager', group: 'roles'},
      {value: 'ROLE_SUBSCRIBER', label: 'Subscriber', group: 'roles'},    
      {value: 'ROLE_PROJECT', label: 'Project', group: 'roles'},    
      {value: 'ROLE_ASSIST', label: 'Assist', group: 'roles'},    
      {value: 'ROLE_AUDIENCE', label: 'Audience', group: 'roles', selected: true  }
    ];

    let structMultiSelectRoles = {};

    $myAuthObject.loading = false;

    onMount( () => {
      edit_recipient_data = $myAuthObject.edit_recipient_data;

      // console.log(edit_recipient_data);
      // structMultiSelectRoles['ROLE_AUDIENCE'] = roleItems[6];


    });

    const recipientUpdateDone = createEventDispatcher();
    function updateRecipientForm(){ recipientUpdateDone("message", true); }

    const checkInputFirstname = () => { 
      inputErrorMessage = '';
      $myAuthObject.inputReady = false; 
      isFirstnameOk = true;
      
      if ( edit_recipient_data.firstName.length === 0 ) { 
        isFirstnameOk = false; 
      }     
      else if ( edit_recipient_data.firstName.length < 2 ) { 
        inputErrorMessage = `${$guiText.general.firstname[my_lang]} ${$guiText.register.general.must_have_min_2_chrs[my_lang]}`;
        isFirstnameOk = false;      
      }    
      else if ( isLastnameOk === true && isFirstnameOk === true) {
        $myAuthObject.inputReady = true; 
      }

    };

    const checkInputLastname = () => { 
      inputErrorMessage = '';
      $myAuthObject.inputReady = false; 
      isLastnameOk = true;

      if ( edit_recipient_data.lastName.length === 0 ) { 
        isLastnameOk = false; 
      }     
      else if ( edit_recipient_data.lastName.length < 2 ) { 
        inputErrorMessage = `${$guiText.general.lastname[my_lang]} ${$guiText.register.general.must_have_min_2_chrs[my_lang]}`;
        isLastnameOk = false;      
      } 
      else if ( isFirstnameOk === true  && isLastnameOk === true) {
        $myAuthObject.inputReady = true; 
      }

    };
    
    const checkInputRoles = () => { 
      inputErrorMessage = '';
      $myAuthObject.inputReady = false; 
      isRolesOk = true;
    }    

    const apiRunner = async () => {
      let edit_usr = {
        'firstname' : edit_recipient_data.firstName ? edit_recipient_data.firstName : '',
        'lastname' : edit_recipient_data.lastName ? edit_recipient_data.lastName : '',
        'uuid' : edit_recipient_data.uuid ? edit_recipient_data.uuid : ''
      }

      let rawresp = await userPut({'authO': $myAuthObject, 'guiTxt': $guiText }, edit_usr);
      let resp = await rawresp.data;
      
      if ( rawresp.status === true ) {
        updateRecipientForm();
        return true;
      }
      else {
          $myAuthObject.loading = false;
          inputErrorMessage = rawresp.message; 
          return false;
        }      
    };

    const checkButtonClick = () => {
      if ( $myAuthObject.inputReady === true ) { apiRunner(); }
      return true;
    };    

    /**
     * @TODO
     * hier muss ein detault wert gesetzt werden,
     * da jeder user mindestens eine default rolle hat
     */

    const handleMultiSelectRoles = (e) => {    
      for ( let i in e.detail ) {
        if ( structMultiSelectRoles.hasOwnProperty(e.detail[i].value) === false ) {
          structMultiSelectRoles[e.detail[i].value] = e.detail[i];
        }
      }

      console.log( structMultiSelectRoles );

    };

    const handleMultiClearRoles = (e) => {
      if ( structMultiSelectRoles.hasOwnProperty(e.detail.value) === true ) {
          delete structMultiSelectRoles[e.detail.value];
      }
  };

</script>



<div class="container mx-auto px-4 h-full">
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full lg:w-12/12 px-4">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0" >
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">

<form>
  
  <div class="relative w-full py-5 mb-3">

    <h3 class="text-2xl mb-2 font-semibold leading-none">
      {$guiText.general.edit_recipient[my_lang]} {edit_recipient_data.lastName}
    </h3>

  <label
    class="block text-blueGray-400 text-xs mb-2"
    for="grid-user"
  > &nbsp; 
  </label>

  <div class="flex flex-col mb-4">
    <label
      class="block text-blueGray-600 text-xs font-bold mb-2"
      for="grid-first-name"
    > {$guiText.general.firstname[my_lang]} </label>   
    <input
      id="grid-first-name"
      type="text"
      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-900 bg-white 
        rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
      bind:value={edit_recipient_data.firstName}
      on:input={checkInputFirstname}
      use:selectTextOnFocus 
      use:blurOnEscape 
    />
  </div>

  <div class="flex flex-col mb-4">
    <label
      class="block text-blueGray-600 text-xs font-bold mb-2"
      for="grid-first-name"
    > {$guiText.general.lastname[my_lang]} </label>         
    <input
      id="grid-last-name"
      type="text"
      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-900 bg-white 
        rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
      bind:value={edit_recipient_data.lastName}
      on:input={checkInputLastname}
      use:selectTextOnFocus    
      use:blurOnEscape                           
    />
  </div>

  <div class="flex flex-col mb-4">

    <label 
      class="block text-blueGray-600 text-xs font-bold mb-2" 
      for="Multiselect">Roles</label>
    <div class="relative flex w-full">
      <Select 
        items={roleItems} 
        isMulti={true}
        on:select={handleMultiSelectRoles}
        on:clear={handleMultiClearRoles}
        ></Select>
    </div>

  </div>

</div>

<div class="text-center mt-6">

  {#if $myAuthObject.inputReady === true}
    {#if $myAuthObject.loading === true}

      <LightGreyButon bg_color = "bg-blueGray-200">
        <div slot="button_text">... {$guiText.general.sending[my_lang]}</div>
      </LightGreyButon>

    {:else}

      <button
        class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 
            rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear 
            transition-all duration-150"
        type="button" 
        on:click={checkButtonClick}                       
      > 
        {$guiText.general.send[my_lang]}
      </button>
    {/if}

  {:else}

    <LightGreyButon bg_color = "bg-blueGray-200">
      <div slot="button_text">{$guiText.general.send[my_lang]}</div>
    </LightGreyButon>

  {/if}


</div>
  

{#if inputErrorMessage.length > 0 }
  <div>
    <span class="block ml-2 text-xs text-red-400">
      {inputErrorMessage}
    </span>                  
  </div>
{/if}



</form>

        </div>
      </div>
    </div>
  </div>
</div>