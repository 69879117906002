<script>
  import { link } from "svelte-routing";
  import {checkForRoleAdmin} from '../../lib/checkAuthObject.js'; 
  import {guiText} from "../../tstore/guiText";  
  import {myAuthObject} from "../../tstore/myauth";
  // core components
  // import NotificationDropdown from "components/Dropdowns/NotificationDropdown.svelte";
  // import UserDropdown from "components/Dropdowns/UserDropdown.svelte";


  export let my_lang;


  // für ausgewählte projekte werden die links auf DE gesezt
  // perleberg: 55109280-13fc-11ee-9534-376b5dabbac4
  let urlLang = my_lang; 
  if ( $myAuthObject.projectId === "55109280-13fc-11ee-9534-376b5dabbac4" ) {
    urlLang = "de";
  }

  let defaultButtonSB = "text-xs py-3 font-bold block prosper_text_a capitalize"; 

  let collapseShow = "hidden";

  // const img_logo_b = "../../assets/img/prosperland_logo.jpg"; 
  const img_logo_b = "../../assets/img/PPL-koml_ws_trans_edited.webp";

  const roleAdminStatus = checkForRoleAdmin($myAuthObject);

  function toggleCollapseShow(classes) { collapseShow = classes; }

  if ( $myAuthObject.hasOwnProperty('shop_status') === false ) {
    $myAuthObject['shop_status'] = { 'status' : 'closed' };
  }

  if ( $myAuthObject.shop_status.hasOwnProperty('status') === false ) { $myAuthObject.shop_status.status = 'closed'; }

  // const gotoProsperConfiguration = () => { 
  //   let myUrl = `${$myAuthObject.uri_prosper_mn}/${$myAuthObject.whoami.uuid}/${$myAuthObject.projectId}/configuration?lang=${urlLang}&useRandomData=onlyData`;    
  //   window.open(myUrl, '_blank').focus(); 
  // };

  // const gotoProsperLandingPage = () => { 
  //   let myUrl = `${$myAuthObject.uri_prosper_mn}/${$myAuthObject.whoami.uuid}/${$myAuthObject.projectId}/prosperland?lang=${urlLang}&status=true&useRandomData=onlyData`;    
  //   window.open(myUrl, '_blank').focus();     
  // };

  // const gotoProsperForum = () => { 
  //   let myUrl = 'https://z327640.invisionservice.com/forum/155-thomson/';    
  //   window.open(myUrl, '_blank').focus();     
  // };

</script>

<nav
  class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden  
        shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
>
   <div
    class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
  >
    <!-- Toggler -->
    <button
      class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border 
            border-solid border-transparent"
      type="button"
      on:click={() => toggleCollapseShow('bg-white m-2 py-3 px-6')}
    >
      <i class="fas fa-bars"></i>
    </button>
    <!-- Brand -->
    <a
      use:link
      class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
      href="/"
    >
      <img
        alt="propserland"
        class="max-w-full rounded-lg"
        src="{img_logo_b}"
        width="60%"
      />
    </a>

    <!-- Collapse -->
    <div class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow 
            absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded {collapseShow}" >
      <!-- Collapse header -->
      <div class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200" >
        <div class="flex flex-wrap">
          <div class="w-6/12">
            <a use:link
              class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
              href="/"
            >
              <img alt="propserland" class="max-w-full rounded-lg" src="{img_logo_b}" width="60%" />
            </a>
          </div>

          <div class="w-6/12 flex justify-end">
            <button
              type="button"
              class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border 
                    border-solid border-transparent"
              on:click={() => toggleCollapseShow('hidden')}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>   

        </div>
      </div>
      
      <!-- Navigation -->

      <ul class="md:flex-col md:min-w-full flex flex-col list-none">

        {#if $myAuthObject['shop_status']['status'] === 'open' } 

          <li class="items-center">
            <a use:link href="/admin/dashboard" class={defaultButtonSB} >
              <i class="fas fa-tv mr-2 text-sm text-blueGray-300"></i>
              {$guiText.general.dashboard[my_lang]} 
            </a> 
          </li>
    
          <li class="items-center">
            <a use:link href="/admin/actioncommunicationboard" class={defaultButtonSB} >
              <i class="fas fa-tower-cell mr-2 text-sm text-blueGray-300"></i>
              {$guiText.general.action_and_communication[my_lang]} 
            </a> 
          </li>

          <li class="items-center">
            <a use:link href="/admin/pieboard" class={defaultButtonSB} >
              <i class="fas fa-chart-pie mr-2 text-sm text-blueGray-300"></i>
              {$guiText.general.pieboard[my_lang]}
            </a>
          </li>          

          <li class="items-center">
            <a use:link href="/admin/momentumboard" class={defaultButtonSB}>
              <i class="fas fa-list-check mr-2 text-sm text-blueGray-300"></i>
                {$guiText.general.momentum_cx[my_lang]}
            </a>
          </li>

          <li class="items-center">
            <a use:link href="/admin/momentumexboard" class={defaultButtonSB}>
              <i class="fas fa-list-check mr-2 text-sm text-blueGray-300"></i>
                {$guiText.general.momentum_ex[my_lang]}
            </a>
          </li>

          <!--
          <li class="items-center">
            <a use:link href="#pablo" on:click={gotoProsperForum} class={defaultButtonSB}>
              <i class="fab fa-forumbee mr-2 text-sm text-blueGray-300"></i>
                {$guiText.general.forum[my_lang]}
            </a>
          </li>
          -->

          <hr  class="my-4 md:min-w-full">
          <h6 class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"> 
            {$guiText.register.general.configure_your_product_label_x[my_lang]}
          </h6>

          <!-- 
            Beispiel für Seitenleiste mit sichtbaren Tabs 

          <li class="items-center">
            <button 
              class="bg-gray-100 text-gray-500 active:bg-gray-200 font-bold uppercase text-xs text-left  
                px-4 py-2 mb-1 md:min-w-full  
                rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150" 
              type="button">
              <i class="fas fa-map mr-2 text-sm text-blueGray-300"></i> {$guiText.general.landingpage[my_lang]}
            </button>
          </li>
          -->

          <!-- li class="items-center">
            <a use:link href="#pablo" on:click={gotoProsperLandingPage} class={defaultButtonSB} >            
              <i class="fas fa-map mr-2 text-sm text-blueGray-300"></i>
                {$guiText.general.landingpage[my_lang]}            
            </a>
          </li -->

          <li class="items-center">
            <a use:link href="/admin/landingpageframe" class={defaultButtonSB}>
              <i class="fas fa-map mr-2 text-sm text-blueGray-300"></i>
              {$guiText.general.landingpage[my_lang]} 
            </a>
          </li>

        {/if}

        {#if $myAuthObject['shop_status']['status'] === 'open' && roleAdminStatus === true} 

          <!-- li class="items-center">
            <a use:link href="#pablo" on:click={gotoProsperConfiguration} class={defaultButtonSB}>
              <i class="fas fa-gear mr-2 text-sm text-blueGray-300"></i>
                {$guiText.general.configuration[my_lang]}
            </a>
          </li -->

          <li class="items-center">
            <a use:link href="/admin/configurepageframe" class={defaultButtonSB}>
              <i class="fas fa-gear mr-2 text-sm text-blueGray-300"></i>
              {$guiText.general.configuration[my_lang]}
            </a>
          </li>
          
        {/if}

        {#if $myAuthObject['shop_status']['status'] === 'open'}
          <hr  class="my-4 md:min-w-full">          
        {/if}


        {#if $myAuthObject['shop_status']['status'] === 'open' && roleAdminStatus === true} 
          <li class="items-center">
            <a use:link href="/admin/recipients" class={defaultButtonSB} >
              <i class="fas fa-users mr-2 text-sm text-blueGray-300" ></i>            
              {$guiText.general.recipients[my_lang]}
            </a>
          </li>
        {/if}

        {#if $myAuthObject['shop_status']['status'] === 'open' } 
          <li class="items-center">
            <a use:link href="/admin/profil" class={defaultButtonSB} >
              <i class="fas fa-user mr-2 text-sm text-blueGray-300"></i>
              {$guiText.general.profil[my_lang]}
            </a>
          </li>        
        {/if}

      </ul>

    </div>
  </div>
</nav>
