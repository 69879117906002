<script>
  // core components
  import RecipientsTableDropdown from "components/Dropdowns/RecipientsTableDropdown.svelte";

  import { navigate } from "svelte-routing"; 
  import { onMount } from "svelte";

  import {tenantsGet} from "../../api/TenantsGet";
  import {surveysGet} from "../../api/SurveysGet";  
  import {usersGet} from "../../api/UsersGet";
  import {userDelete} from "../../api/UserDelete";
  
  import {guiText} from "../../tstore/guiText"; 
  import {myAuthObject} from "../../tstore/myauth";
  import {checkForRoleAdmin} from '../../lib/checkAuthObject.js';      
  
  import UsersForm from "components/Cards/UsersForm.svelte";  
  import RecipientEdit from "components/Cards/RecipientEdit.svelte";    
  import RecipientCsvUpload from "components/Cards/RecipientCsvUpload.svelte";    

  // can be one of light or dark
  export let color = "light";
  export let my_lang;  

  let registerBg2 = "/assets/img/landingpage_bg_en.jpg";
  if (my_lang == "de") {
    registerBg2 = "/assets/img/landingpage_bg_de.jpg";        
    // registerBg2 = "/assets/img/register_bg_2.jpg"; 
  }

  let table_content_class = "border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4";
  let button_popup_class = "bg-red-400 text-white float-right active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow "; 
  button_popup_class += "hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150";

  const roleAdminStatus = checkForRoleAdmin($myAuthObject);
  let usersAry = [];
  let tenantsAry = [];
  let tenantsO = {};
  let tenantsLabel = '';
  // let surveysAry = [];
  let surveys_by_tenant = {};
  // let surveys_by_tenant_survey = {};  
  // let surveys_by_tenant_bot = {};   
  let surveysLabel = ''; 

  let showModalAddRecipient = false;
  function toggleModalAddRecipient(e=true){ 
    showModalCsvRecipient = false;
    showModalEditRecipient = false;    
    showModalAddRecipient = !showModalAddRecipient; };

  let showModalCsvRecipient = false;
  function toggleModalUploadCsvRecipient(e=true){ 
    showModalAddRecipient = false;
    showModalEditRecipient = false;
    showModalCsvRecipient = !showModalCsvRecipient; };

  let showModalEditRecipient = false;
  function toggleModalEditRecipient(e=true){
    showModalAddRecipient = false; 
    showModalCsvRecipient = false;    
    showModalEditRecipient = !showModalEditRecipient;     
  };

  const updateRecipientTableContent = async () => {
    let rawresp = await usersGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    usersAry = await rawresp.data;
    return true;    
  }

  const toggleModalCsvRecipientWithUpdate = async () => {
    await updateRecipientTableContent();
    // toggleModalAddRecipient();
    toggleModalUploadCsvRecipient(); 
    // toggleModalCsvRecipientWithUpdate();
  };

  const toggleModalAddRecipientWithUpdate = async () => {
    await updateRecipientTableContent();
    toggleModalAddRecipient(); 
  };

  const toggleModalEditRecipientWithUpdate = async () => {
    await updateRecipientTableContent();
    toggleModalEditRecipient(); 
  };  

  const handle_delete_recipient = async (e) => {
    await userDelete({'authO': $myAuthObject, 'guiTxt': $guiText }, e.detail.uuid );
    await updateRecipientTableContent();    
    return true;
  }

  const handle_landingpage_recipient = (e) => { 
    let myUrl = `${$myAuthObject.uri_prosper_mn}/${e.detail.uuid}/landingpage?lang=${my_lang}`;
    window.open(myUrl, '_blank').focus();     
  };

  const handle_edit_recipient = async (e) => {
    $myAuthObject.edit_recipient = e.detail.uuid;

    let userEditAry = usersAry.filter( function (itm) { 
          if ( itm.uuid === $myAuthObject.edit_recipient) { 
            return true; }
          else { 
            return false; } 
        });

    $myAuthObject.edit_recipient_data = userEditAry[0];

    toggleModalEditRecipient();
    return true;
  }

  const handle_lock_recipient = async (e) => {
    alert(`Diese Funktion wurde bisher nur erwähnt, es existiert kein Backend dafür! Der entsprechende User (${e.detail.uuid}) soll gesperrt, aber nicht gelöscht werden.`);
    return true;
  }

  onMount(async () => { 
    if ( roleAdminStatus === true ) {
        let tenantsRawResp = await tenantsGet({'authO': $myAuthObject, 'guiTxt': $guiText });
        tenantsAry = await tenantsRawResp.data; 
        tenantsO = await tenantsRawResp.o;

        let surveysRawResp = await surveysGet({'authO': $myAuthObject, 'guiTxt': $guiText });
        surveys_by_tenant = await surveysRawResp.by_tenant;
        let rawresp = await usersGet({'authO': $myAuthObject, 'guiTxt': $guiText });
        usersAry = await rawresp.data;  

        tenantsLabel = tenantsAry[0].label;
        surveysLabel = surveys_by_tenant[tenantsAry[0].uuid][0]['label'];
    } 
    else { navigate('/admin/dashboard', { replace: true });  }
  });

</script>


  {#if showModalAddRecipient || showModalEditRecipient || showModalCsvRecipient}

  <div id="prosper_overlay"></div>

    <div class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">  
      <div class="overflow-x-hidden overflow-y-auto fixed z-50" > <!-- inset-0    outline-none focus:outline-none justify-center items-center flex" -->
        <div class="border-0 rounded-lg shadow-lg w-full h-full bg-white" style="background-image: url({registerBg2});" >                    
          <div class="mb-0 px-4 py-3 border-0"> 
            
            {#if showModalAddRecipient}
              <button class="{button_popup_class}" type="button" on:click={toggleModalAddRecipient} > x </button>
            {/if}
            {#if showModalEditRecipient} 
              <button class="{button_popup_class}" type="button" on:click={toggleModalEditRecipient} > x </button>
            {/if}
            {#if showModalCsvRecipient} 
              <button class="{button_popup_class}" type="button" on:click={toggleModalUploadCsvRecipient} > x </button>
            {/if}            
            
          </div>                
 
          <div class="relative p-12 flex">

            {#if showModalAddRecipient}            
              <UsersForm my_lang={my_lang} on:message={toggleModalAddRecipientWithUpdate}/>
            {/if}

            {#if showModalEditRecipient}
              <RecipientEdit my_lang={my_lang} on:message={toggleModalEditRecipientWithUpdate}/>            
            {/if}

            {#if showModalCsvRecipient}
              <RecipientCsvUpload my_lang={my_lang} on:message={toggleModalCsvRecipientWithUpdate}/>            
            {/if}            


          </div>
        </div>
      </div>
      <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
    
  {/if}

  <div > <!-- div>class="container mx-auto px-4 h-full "  -->
    <div class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">

    <div class="rounded-t mb-0 px-4 z-30 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <div class="text-center content-center items-center flex justify-between"> 
            <h3 class="lg:w-8/12 font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}" >
              {$guiText.general.recipients[my_lang]}
            </h3>

            <button
              class="lg:w-2/12 bg-emerald-500 text-white active:bg-emerald-400 font-bold uppercase text-xs 
                px-4 py-1 rounded shadow hover:shadow-md outline-none 
                focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              on:click={toggleModalUploadCsvRecipient}
            > {$guiText.general.upload_csv[my_lang]}</button>

            <button
              class="lg:w-2/12 bg-emerald-500 text-white active:bg-emerald-400 font-bold uppercase text-xs 
                px-4 py-1 rounded shadow hover:shadow-md outline-none 
                focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              on:click={toggleModalAddRecipient}
            > {$guiText.general.add_recipients[my_lang]}</button>

          </div>
        </div>
      </div>
    </div>

    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            {#each [ $guiText.general.recipients[my_lang], 
                    $guiText.general.email[my_lang],
                    $guiText.general.role[my_lang], 
                    $guiText.general.approved[my_lang],
                    ' '] as headItm }
              <th class="px-6 align-middle border border-solid py-3 text-xs capitalize border-l-0 border-r-0 whitespace-nowrap font-semibold text-left 
                {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600' }"> 
                {headItm} </th>
            {/each}
          </tr>
        </thead>

        <tbody>

          {#each usersAry as tele}

            <tr>
              <th class="{table_content_class} text-left flex items-center" >
                  <span class="ml-3 font-bold {color === 'light' ? 'btext-blueGray-600' : 'text-white'}" >
                    { tele.valid === true ?  tele.firstName + ' ' + tele.lastName : '---' }
                  </span>
                
              </th>
              <td class="{table_content_class}" > {tele.email} </td>
              <td class="{table_content_class}"> {tele.tenantRoles[0].roles} </td>            
              <td class="{table_content_class}" >
                <i class="fas fa-circle mr-2 {tele.valid === true ? 'text-emerald-500' : 'text-red-500' }"></i>
              </td>

              <td class="{table_content_class} text-right" >
                <RecipientsTableDropdown 
                  my_lang="{my_lang}"
                  recipient="{tele}" 
                  on:delete_recipient={handle_delete_recipient}
                  on:landingpage_recipient={handle_landingpage_recipient} 
                  on:edit_recipient={handle_edit_recipient}
                  on:lock_recipient={handle_lock_recipient}
                  />
              </td>

            </tr>

          {/each}

        </tbody>
      </table>
    </div>


  </div>
</div>
